export const AUTH_COOKIE_NAME = 'oi_act'
export const V1_AUTH_COOKIE_NAME = '_WantMyMoney_session'
export const AUTH_REFRESH_COOKIE_NAME = 'oi_rt'
export const AUTH_COOKIE_REMEMBER_COMPUTER = 'oi_trusted_computer'

export const AUTH_ROUTE_LOADER_DATA_ID = 'authentication'

export enum OauthTokenType {
  FirstParty = 'FIRST_PARTY',
  // Old ones
  ThirdParty = 'WardenOauthProvider::Token::Access',
}

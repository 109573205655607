import { useNavigate, useParams } from 'react-router-dom'
import { useOnMountEffect } from '@oi/react/modules/utils'

export default function ResetPasswordRoute() {
  const params = useParams()
  const navigate = useNavigate()

  useOnMountEffect(() => {
    let searchParams = ''
    if (params.resetCode) {
      searchParams = `?resetCode=${params.resetCode}`
    }

    navigate(`/login${searchParams}`, { replace: true })
  })

  return null
}

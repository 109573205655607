import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { formHelperTextClasses } from '@mui/material/FormHelperText'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import { CheckboxBlankLineIcon, CheckboxFillIcon, CheckboxIndeterminateLineIcon } from '@oi/react/components/icons'

import type { BaseField } from '../fields.interface'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase, { FieldBaseProps, StyledInputLabel } from './field.base.component'
import { fieldClasses } from './field.classes'

export interface FieldCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BaseField<TFieldValues, TName> {
  color?: CheckboxProps['color']
  size?: CheckboxProps['size']
  checkedValue?: boolean | string
  uncheckedValue?: boolean | string
}

const StyledFieldBase = styled(FieldBase)<FieldBaseProps>(({ theme }) => ({
  [`& .${formHelperTextClasses.root}`]: {
    marginTop: 6,
    marginLeft: 26,

    [`&:not(.${formHelperTextClasses.error})`]: {
      marginTop: 0,
      ...theme.typography.caption
    }
  },

  [`& .${inputLabelClasses.root}`]: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(0.5, 0)
  }
}))

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  padding: 3
}))

export default function FieldCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  hint,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  // placeholder,
  // autoComplete,
  // autoFocus,
  skeleton,
  // readOnly,
  // onBlur,
  onClick,

  checkedValue = true,
  uncheckedValue = false,

  size,
  color
}: FieldCheckboxProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  const handleOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    field.onChange({
      ...event,
      target: {
        ...event.target,
        value: checked ? checkedValue : uncheckedValue
      }
    })
  }, [checkedValue, field, uncheckedValue])

  return (
    <StyledFieldBase
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      // label={label}
      // labelVariant={labelVariant}
      name={field.name}
      onClick={onClick}
      required={required}
      skeleton={skeleton}>
      <FormControlLabel
        control={
          <StyledCheckBox
            ref={field.ref}
            checked={field.value === checkedValue}
            checkedIcon={<CheckboxFillIcon />}
            color={color}
            disabled={field.disabled || controller.disabled}
            icon={<CheckboxBlankLineIcon />}
            id={`field.${field.name}`}
            indeterminateIcon={<CheckboxIndeterminateLineIcon />}
            name={field.name}
            onBlur={field.onBlur}
            onChange={handleOnChange}
            onKeyDown={controller.onKeyDown}
            size={size}
          />
        }
        label={(
          <StyledInputLabel
            htmlFor={`field.${field.name}`}
            required={false}>
            {label}

            {required && (
              <span className={fieldClasses.requiredAstrix}>
                &nbsp;*
              </span>
            )}
          </StyledInputLabel>
        )}
      />
    </StyledFieldBase>
  )
}

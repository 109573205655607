import React from 'react'
import { FormattedNumber, IntlShape, useIntl } from 'react-intl'
import { CREDIT_CHECK_PRICE, PRICING_CURRENCY } from '@oi/constants/prices'

import type { RegisterProps } from '../register.component'

interface TooltipInfoPoint {
  order: string,
  title: string,
  description: string | React.ReactNode
}

function baseRegistrationPoints(intl: IntlShape): Record<number, TooltipInfoPoint> {
  return {
    1: {
      order: '1.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.point-1.title',
        defaultMessage: 'Create an account'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.point-1.description',
        defaultMessage: 'Simply create a free account with Online Incasso. Don\'t worry, nothing has happened yet.'
      })
    }
  }
}

function incassoRegistrationPoints(intl: IntlShape): Record<number, TooltipInfoPoint> {
  return {
    2: {
      order: '2.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-2.title',
        defaultMessage: 'Incasso start'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-2.description',
        defaultMessage: 'Once your account has been created you can start a case.'
      })
    },
    3: {
      order: '3.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-3.title',
        defaultMessage: 'Fill in data'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-3.description',
        defaultMessage: 'When starting a collection case you will have to enter the details of the debtor and yourself.'
      })
    },
    4: {
      order: '4.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-4.title',
        defaultMessage: 'Pay'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-4.description',
        defaultMessage: 'Once you have completed everything, the payment process can begin. You pay a fixed price per case.'
      })
    },
    5: {
      order: '5.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-5.title',
        defaultMessage: 'Incasso start'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-5.description',
        defaultMessage: 'We will take over from you for now and send a letter to the debtor.'
      })
    }
  }
}

function creditRegistrationPoints(intl: IntlShape): Record<number, TooltipInfoPoint> {
  return {
    2: {
      order: '2.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-2.title',
        defaultMessage: 'Request a credit report'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-2.description',
        defaultMessage: 'Find the company you want to check creditworthiness for.'
      })
    },
    3: {
      order: '3.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-3.title',
        defaultMessage: 'Pay'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-3.description',
        defaultMessage: 'You pay a fixed price of {price} (excl. VAT) for the credit report.'
      }, {
        price: (
          <FormattedNumber
            currency={PRICING_CURRENCY}
            style={'currency'}
            value={CREDIT_CHECK_PRICE} />
        )
      })
    },
    4: {
      order: '4.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-4.title',
        defaultMessage: 'Received credit check'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-4.description',
        defaultMessage: 'After payment you will directly receive the report.'
      })
    }
  }
}

export function useRegistrationTooltipPoints(type: RegisterProps['type']): Record<number, TooltipInfoPoint> {
  const intl = useIntl()

  switch (type) {
    case 'default':
    case 'incasso':
      return {
        ...baseRegistrationPoints(intl),
        ...incassoRegistrationPoints(intl)
      }

    case 'credit-check':
      return {
        ...baseRegistrationPoints(intl),
        ...creditRegistrationPoints(intl)
      }
  }
}

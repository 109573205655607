import generateUtilityClasses from '@mui/utils/generateUtilityClasses'

export const fieldClasses = generateUtilityClasses('Field', [
  'root',
  'disabled',
  'hidden',
  'inputLabel',
  'inputLabelThin',
  'groupLabel',
  'groupContainer',
  'groupChild',
  'field',
  'input',
  'requiredAstrix',
  'collectionDeleteButton'
])

import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { companyApi } from './api.constants'
import { unwrapResponse, updateReactQueryCache, useWrapMethod } from './api.internal'
import { queryClient } from './api.methods'
import {
  Administration,
  CompanyApiAdministrationsCreateOneRequest,
  CompanyApiAdministrationsDeleteOneRequest,
  CompanyApiAdministrationsUpdateOneRequest,
  CompanyApiAdministrationUploadLogoRequest,
  CompanyApiThirdPartyRevokeAccessTokenRequest,
  CompanyApiUsersCreateOneRequest,
  CompanyApiUsersDeleteOneRequest,
  Me,
  ThirdPartyToken,
  User,
  UserConnection
} from './open-api'

function getThirdPartyTokenQueryOptions() {
  return {
    queryKey: ['company', 'third-party', 'tokens'],
    queryFn: () => unwrapResponse(companyApi.thirdPartyAccessTokens())
  }
}

export function listThirdPartyTokens() {
  return queryClient.ensureQueryData(getThirdPartyTokenQueryOptions())
}

export function useThirdPartyTokens() {
  return useQuery<Array<ThirdPartyToken>>(getThirdPartyTokenQueryOptions())
}

export function useRevokeThirdPartyTokenMutation() {
  return useWrapMethod<
    CompanyApiThirdPartyRevokeAccessTokenRequest,
    ThirdPartyToken,
    Array<ThirdPartyToken>
  >(
    (params) => companyApi.thirdPartyRevokeAccessToken(params),
    () => ['company', 'third-party', 'tokens'],
    (existing: Array<ThirdPartyToken>, data: ThirdPartyToken) => existing.map((token) => {
      if (token.id === data.id) {
        return data
      }

      return token
    })
  )
}

export function useCreateAdministrationMutation() {
  return useWrapMethod<
    CompanyApiAdministrationsCreateOneRequest,
    Administration,
    Me
  >(
    (params) => companyApi.administrationsCreateOne(params),
    () => ['me'],
    // We are going to update a different cache key
    (existing, data) => ({
      ...existing,
      administrations: [
        ...existing.administrations,
        data
      ]
    })
  )
}

export function useAdministration() {
  const params = useParams<{ id: string }>()

  return useQuery({
    queryKey: ['administration', params.id],
    queryFn: () => unwrapResponse(companyApi.administrationsFindById({ id: params.id as never }))
  })
}

export function useUpdateAdministrationMutation() {
  return useWrapMethod<
    CompanyApiAdministrationsUpdateOneRequest,
    Administration
  >(
    (params) => companyApi.administrationsUpdateOne(params),
    async (params, data) => {
      updateReactQueryCache(['me'], data, params, (existing: Me, data: Administration) => updateAdministrationInCache(existing, data))
      updateReactQueryCache(['administration', `${params.id}`], data, params, (existing: Administration, data: Administration) => ({
        ...existing,
        ...data,
        ...params.updateAdministration
      }))
    }
  )
}

export function useDeleteAdministrationMutation() {
  return useWrapMethod<
    CompanyApiAdministrationsDeleteOneRequest,
    Administration,
    Me
  >(
    (params) => companyApi.administrationsDeleteOne(params),
    () => ['me'],
    (existing, _, params) => ({
      ...existing,
      administrations: existing.administrations.filter(({ id }) => id !== params.id)
    })
  )
}

export function useUploadAdministrationLogoMutation() {
  return useWrapMethod<
    CompanyApiAdministrationUploadLogoRequest,
    Administration,
    Me
  >(
    (params) => companyApi.administrationUploadLogo(params),
    () => ['me'],
    (existing, data) => updateAdministrationInCache(existing, data)
  )
}

function updateAdministrationInCache(existing: Me, data: Administration): Me {
  return {
    ...existing,
    administrations: existing.administrations.map((administration) => {
      if (administration.id === data.id) {
        return data
      }

      return administration
    })
  }
}

function getUsersQueryOptions() {
  return {
    queryKey: ['company', 'users'],
    queryFn: () => unwrapResponse(companyApi.usersQueryMany())
  }
}

export function listUsers() {
  return queryClient.ensureQueryData(getUsersQueryOptions())
}

export function useUsers() {
  return useQuery(getUsersQueryOptions())
}

export function useCreateUserMutation() {
  return useWrapMethod<
    CompanyApiUsersCreateOneRequest,
    User,
    UserConnection
  >(
    (params) => companyApi.usersCreateOne(params),
    () => ['company', 'users'],
    (existing: UserConnection, data) => existing && ({
      ...existing,
      nodes: [
        ...existing?.nodes ?? [],
        data
      ]
    })
  )
}

export function useDeleteUserMutation() {
  return useWrapMethod<
    CompanyApiUsersDeleteOneRequest,
    User,
    UserConnection
  >(
    (params) => companyApi.usersDeleteOne(params),
    () => ['company', 'users'],
    (existing: UserConnection, _, params) => ({
      ...existing,
      nodes: existing.nodes.filter(({ id }) => id !== params.id)
    })
  )
}

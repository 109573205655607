import React from 'react'
import { FormattedMessage } from 'react-intl'
import Divider from '@mui/material/Divider'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import Link, { linkClasses } from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton'
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography, { typographyClasses } from '@mui/material/Typography'
import { LogoutCircleRLineIcon } from '@oi/react/components/icons'
import { useActiveUser } from '@oi/react/modules/api'

import { useLinkItems, useMenuItems } from './header-items.hooks'

interface HeaderDrawerProps {
  isOpen: boolean
  onClose: () => void
  logout: () => void
}

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  padding: 0,
  zIndex: theme.zIndex.appBar - 1,

  [theme.breakpoints.up('md')]: {
    display: 'none'
  },

  [`& .${drawerClasses.paper}`]: {
    backgroundColor: theme.palette.secondary.dark,
    padding: 0,
    paddingTop: theme.spacing(12),
    minWidth: '100%'
  },
  [`& .${listItemButtonClasses.root}, & .${linkClasses.root}`]: {
    width: '100%',
    padding: theme.spacing(0, 1)
  }
}))

const StyledLogoutButton = styled(ListItemButton)(({ theme }) => ({
  paddingBottom: `${theme.spacing(6)} !important`,
  [`& .${listItemIconClasses.root}`]: {
    minWidth: 34
  },
  '& svg': {
    fill: theme.palette.primary.main
  },
  [`.${typographyClasses.root}`]: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    fontWeight: 600
  }
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5)
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [`.${typographyClasses.root}`]: {
    ...theme.typography.body1,
    color: theme.palette.common.white,
    fontWeight: 600
  },
  padding: 0
}))

const StyledUserTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light
}))

export default function HeaderDrawer({ isOpen, onClose, logout }: HeaderDrawerProps) {
  const activeUser = useActiveUser()

  const menuItems = useMenuItems()
  const linkItems = useLinkItems()

  return (
    <StyledDrawer
      anchor={'left'}
      onClose={onClose}
      open={isOpen}>
      <Stack
        flexGrow={1}
        justifyContent={'space-between'}>
        <Stack alignItems={'center'}>
          <List>
            {linkItems.map(({ to, label }) => (
              <ListItem key={to}>
                <Link
                  href={to}>
                  <StyledListItemText
                    primaryTypographyProps={{ align: 'center' }}>
                    {label}
                  </StyledListItemText>
                </Link>
              </ListItem>
            ))}

            <StyledDivider variant={'fullWidth'} />

            <ListItem>
              <ListItemText>
                <StyledUserTypography
                  align={'center'}
                  fontWeight={'medium'}
                  variant={'body1'}>
                  {activeUser.name}
                </StyledUserTypography>
              </ListItemText>
            </ListItem>

            {menuItems.map(({ key, to, label }) => (
              <ListItem key={key}>
                <Link href={to}>
                  <StyledListItemText
                    primaryTypographyProps={{ align: 'center' }}>
                    {label}
                  </StyledListItemText>
                </Link>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack alignItems={'center'}>
          <List>
            <ListItem>
              <StyledLogoutButton onClick={logout}>
                <ListItemIcon>
                  <LogoutCircleRLineIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage
                    defaultMessage={'Log out'}
                    id={'menu.user.logout'} />
                </ListItemText>
              </StyledLogoutButton>
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </StyledDrawer>
  )
}

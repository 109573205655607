import React from 'react'
import { useIntl } from 'react-intl'
import FormControl from '@mui/material/FormControl'
import FormHelperText, { formHelperTextClasses } from '@mui/material/FormHelperText'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Skeleton, { skeletonClasses } from '@oi/react/components/skeleton'
import clsx from 'clsx'

import type { BaseField, FieldValue } from '../fields.interface'
import type { FieldError } from 'react-hook-form'

import { useFieldsController } from '../fields.hooks'
import { fieldClasses } from './field.classes'

const Root = styled(Stack)({
  position: 'relative',

  [`&.${fieldClasses.hidden}`]: {
    display: 'none'
  },

  [`& .${formHelperTextClasses.root}`]: {
    lineHeight: '1'
  }
})

export const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'labelSize'
})<InputLabelProps>(({ theme }) => ({
  position: 'relative',
  transform: 'none',

  ...theme.typography.subtitle2,

  lineHeight: 1.3,
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 0, 0.5),

  [`&.${fieldClasses.inputLabelThin}`]: {
    ...theme.typography.body1,
    fontSize: theme.typography.subtitle2.fontSize
  },

  [`& .${skeletonClasses.root}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

export interface FieldBaseProps extends Omit<BaseField, 'name' | 'placeholder' | 'autoComplete' | 'validations' | 'defaultValue' | 'autoFocus' | 'readOnly'> {
  name: string | null
  error?: FieldError
  summaryValue?: FieldValue
  /**
   * If `true` then don't wrap the summary in Typography
   */
  summaryIsElement?: boolean
  isGroup?: boolean
  className?: string
}

export default function FieldBase({
  children,
  className,
  label,
  labelVariant,
  hint,
  name,
  disabled = false,
  required = false,
  isGroup = false,
  error,
  skeleton: skeletonProp,
  onClick,
  hidden
}: React.PropsWithChildren<FieldBaseProps>) {
  const { disabled: fieldsDisabled, skeleton: fieldsSkeleton } = useFieldsController()
  const intl = useIntl()

  const skeleton = React.useMemo(() => {
    return fieldsSkeleton || skeletonProp
  }, [fieldsSkeleton, skeletonProp])

  const errorMessage = React.useMemo(() => {
    if (error) {
      if (error.message) {
        return error.message

      } else {
        // Some build in error messages
        switch (error.type) {
          case 'required': {
            return intl.formatMessage({
              id: 'field-error.required',
              defaultMessage: 'This field is required!'
            })
          }

          case 'field-error.file.to-big.5mb': {
            return intl.formatMessage({
              id: 'field-error.file.to-big.5mb',
              defaultMessage: 'File is too big. Max size is 5MB'
            })
          }
        }
      }
    }

    return null
  }, [error, intl])

  return (
    <Root
      onClick={onClick}
      className={clsx(fieldClasses.root, className, {
        [fieldClasses.hidden]: hidden,
        [fieldClasses.disabled]: disabled || fieldsDisabled
      })}>
      <FormControl
        disabled={disabled || fieldsDisabled}
        error={!!error}
        required={required}
        variant={'standard'}
        fullWidth>
        {label && (
          <StyledInputLabel
            error={!!error}
            htmlFor={name ? `field.${name}` : undefined}
            required={false}
            className={clsx(fieldClasses.inputLabel, {
              [fieldClasses.inputLabelThin]: labelVariant === 'thin'
            })}
            shrink>
            <Skeleton
              childrenType={'typography'}
              enabled={skeleton}
              width={'30%'}>
              {label}

              {required && (
                <span className={fieldClasses.requiredAstrix}>
                    &nbsp;*
                </span>
              )}
            </Skeleton>
          </StyledInputLabel>
        )}

        <Skeleton
          enabled={skeleton && !isGroup}
          width={'100%'}>
          {children}
        </Skeleton>

        {(error || hint) && (
          <FormHelperText>
            <Skeleton
              childrenType={'typography'}
              enabled={skeleton}
              width={'40%'}>
              {errorMessage || hint}
            </Skeleton>
          </FormHelperText>
        )}
      </FormControl>
    </Root>
  )
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { TutorialTooltipContainer } from '@oi/react/components/tutorial-tooltip'

export default function TutorialMenu() {
  return (
    <TutorialTooltipContainer
      header={
        <FormattedMessage
          defaultMessage={'Your details'}
          id={'tutorial.menu.header'}
        />
      }>
      <Stack spacing={3}>
        <Typography>
          <FormattedMessage
            defaultMessage={'Provide your details now, or you can complete this later when starting a case.'}
            id={'tutorial.menu.text-1'}
          />
        </Typography>
        {/*<Typography>*/}
        {/*  <FormattedMessage*/}
        {/*    defaultMessage={'Did you know that you can easily and quickly connect your accounting system with us? You can also do that here!'}*/}
        {/*    id={'tutorial.menu.text-2'}*/}
        {/*  />*/}
        {/*</Typography>*/}
      </Stack>
    </TutorialTooltipContainer>
  )
}

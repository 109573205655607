import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { typographyClasses } from '@mui/material/Typography'

interface ExtendedTooltipProps extends TooltipProps {
  autoWidth?: boolean
  variant?: 'default' | 'primary' | 'secondary' | 'tutorial'
}

const StyledTooltip = styled(({ className, variant, autoWidth, ...props }: ExtendedTooltipProps) => (
  <Tooltip
    {...props}
    arrow={variant !== 'default'}
    classes={{ popper: className }} />
))<ExtendedTooltipProps>(({ theme, variant, autoWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: '#F5F5FF',
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    margin: 0,

    [`& .${typographyClasses.root}.${typographyClasses.inherit}`]: {
      ...theme.typography.subtitle2,
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightRegular,

      '& a': {
        fontWeight: theme.typography.fontWeightBold
      }
    },

    ...(variant !== 'default' && {
      maxWidth: 270,
      padding: theme.spacing(2),

      [`& .${typographyClasses.root}`]: {
        color: theme.palette.common.white
      }
    }),

    ...(variant === 'primary' && {
      backgroundColor: '#D10073',
      color: theme.palette.common.white,

      [`& .${tooltipClasses.arrow}`]: {
        color: '#D10073'
      }
    }),

    ...(variant === 'secondary' && {
      backgroundColor: theme.palette.secondary.main,
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.secondary.main
      }
    }),

    ...(variant === 'tutorial' && {
      maxWidth: 400,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,

      [`& .${typographyClasses.root}`]: {
        color: theme.palette.text.primary,
        lineHeight: '26px'
      }
      ,
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.background.default,
        height: theme.spacing(5),
        width: theme.spacing(3)
      },

      [`&.${tooltipClasses.tooltipPlacementRight}`]: {
        marginLeft: `${theme.spacing(3)} !important`,
        [`& .${tooltipClasses.arrow}`]: {
          marginLeft: theme.spacing(-3)
        }
      },

      [`&.${tooltipClasses.tooltipPlacementLeft}`]: {
        marginRight: `${theme.spacing(3)} !important`,
        [`& .${tooltipClasses.arrow}`]: {
          marginRight: theme.spacing(-3)
        }
      }
    }),

    ...(autoWidth && {
      maxWidth: 'none'
    })
  }
}))

export default function ExtendedTooltip({
  variant = 'default',
  children,
  ...tooltipProps
}: ExtendedTooltipProps) {
  return (
    <StyledTooltip
      {...tooltipProps}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      variant={variant}>
      {children}
    </StyledTooltip>
  )
}

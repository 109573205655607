import { API_BASE_PATH, creditCaseAPi } from './api.constants'
import { getCreditCaseQueryKey } from './api.credit-case'
import { useWrapMethod } from './api.internal'
import { refetchQueries } from './api.methods'
import {
  CaseAttachment,
  CreditCasesApiCaseAttachmentDeleteOneRequest,
  CreditCasesApiCaseAttachmentUploadPreIncassoRequest,
  CreditCasesApiCaseAttachmentUploadRequest,
  CreditCasesApiInvoicesUploadRequest,
  FindCreditCase,
  Invoice
} from './open-api'

const API_PATH = '/v2.0'

export function getCaseAttachmentDownloadUrl(id: number, creditCaseId: number | string, forDebtor?: boolean): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    forDebtor && '/debtor',
    `/credit-cases/${creditCaseId}/case-attachment/${id}/download`
  ].filter(Boolean).join('')
}

export function getMessageAttachmentDownloadUrl(id: number, creditCaseId: number | string, messageId: number | string, forDebtor?: boolean): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    forDebtor && '/debtor',
    `/credit-cases/${creditCaseId}/messages/${messageId}/attachments/${id}/download`
  ].filter(Boolean).join('')
}

export function getInvoiceDownloadUrl(id: number, creditCaseId: number | string, forDebtor?: boolean): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    forDebtor && '/debtor',
    `/credit-cases/${creditCaseId}/invoices/${id}/download`
  ].filter(Boolean).join('')
}

export function getCaseActionLetterUrl(id: number, creditCaseId: number): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    `/credit-cases/${creditCaseId}/letters/${id}`
  ].filter(Boolean).join('')
}

export function getCaseActionEmailUrl(id: number, creditCaseId: number): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    `/credit-cases/${creditCaseId}/emails/${id}`
  ].filter(Boolean).join('')
}

export function getBailiffLogoUrl(id: number): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    `/bailiffs/${id}/logo`
  ].filter(Boolean).join('')
}

export function getCompanyLogoUrl(): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    '/company/logo'
  ].filter(Boolean).join('')
}

export function geAdministrationLogoUrl(id: number): string {
  return [
    API_BASE_PATH || '/api',
    API_PATH,
    `/company/administrations/${id}/logo`
  ].filter(Boolean).join('')
}

export function useUploadCreditCaseAttachment() {
  return useWrapMethod<
    CreditCasesApiCaseAttachmentUploadRequest,
    CaseAttachment
  >(
    (params) => creditCaseAPi.caseAttachmentUpload(params),
    (params) => getCreditCaseQueryKey(params.creditCaseId),
    // @ts-expect-error we're updating another cache item
    (existing: FindCreditCase, data: CaseAttachment): FindCreditCase => ({
      ...existing,
      attachments: [
        ...(existing as never as FindCreditCase).attachments,
        data
      ]
    })
  )
}

export function useUploadCreditCasePreIncassoAttachment() {
  return useWrapMethod<
    CreditCasesApiCaseAttachmentUploadPreIncassoRequest,
    CaseAttachment
  >(
    (params) => creditCaseAPi.caseAttachmentUploadPreIncasso(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}

export function useDeleteCreditCaseAttachment() {
  return useWrapMethod<
    CreditCasesApiCaseAttachmentDeleteOneRequest,
    void
  >(
    (params) => creditCaseAPi.caseAttachmentDeleteOne(params),
    (params) => getCreditCaseQueryKey(params.creditCaseId),
    // @ts-expect-error we're updating another cache item
    (existing: FindCreditCase, data, params): FindCreditCase => ({
      ...existing,
      attachments: (existing as never as FindCreditCase).attachments.filter(({ id }) => id !== params.id)
    })
  )
}

export function useUploadCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesUploadRequest,
    Invoice
  >(
    (params) => creditCaseAPi.invoicesUpload(params),
    (params) => getCreditCaseQueryKey(params.creditCaseId),
    // @ts-expect-error we're updating another cache item
    (existing: FindCreditCase, data: Invoice): FindCreditCase => ({
      ...existing,
      invoices: existing.invoices.map((invoice) => {
        if (invoice.id === data.id) {
          return data
        }

        return invoice
      })
    })
  )
}

export function useUploadCreditCaseInvoiceMutationWithReload() {
  return useWrapMethod<
    CreditCasesApiInvoicesUploadRequest,
    Invoice
  >(
    (params) => creditCaseAPi.invoicesUpload(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}

import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom'
import { AUTH_ROUTE_LOADER_DATA_ID } from '@oi/constants/auth'
import { ErrorBoundaryFallback } from '@oi/react/components/error-boundary'
import FullScreenLoader from '@oi/react/components/full-screen-loader'
import { PageView } from '@oi/react/modules/analytics'
import AbilityProvider from '@oi/react/modules/auth'

import App, { appLoader } from './app'
import LoginRoute from './auth/login'
import AuthorizeRoute from './auth/oauth/authorize'
import RegisterRoute from './auth/register'
import ResetPasswordRoute from './auth/reset-password'

function defaultLoader() {
  return {}
}

const appRoutes = createBrowserRouter(createRoutesFromElements(
  <Route
    errorElement={<ErrorBoundaryFallback withGoToDashboard />}
    element={(
      <PageView />
    )}>
    <Route
      element={<LoginRoute />}
      loader={defaultLoader}
      path={'/login'} />

    <Route
      loader={defaultLoader}
      path={'/signup'}
      element={(
        <Navigate
          to={'/register'}
          replace />
      )} />

    <Route
      loader={defaultLoader}
      path={'/register'}>

      <Route
        element={<RegisterRoute type={'incasso'} />}
        loader={defaultLoader}
        path={'incasso'} />

      <Route
        element={<RegisterRoute type={'credit-check'} />}
        loader={defaultLoader}
        path={'credit-check'} />

      <Route
        element={<RegisterRoute type={'default'} />}
        index />

    </Route>

    <Route
      element={<AuthorizeRoute />}
      loader={defaultLoader}
      path={'/oauth/authorize'} />

    <Route
      element={<ResetPasswordRoute />}
      loader={defaultLoader}
      path={'/reset/:resetCode'} />

    <Route
      id={AUTH_ROUTE_LOADER_DATA_ID}
      loader={appLoader}
      element={(
        <AbilityProvider />
      )}>
      <Route
        element={<App />}
        id={'dashboard'}
        path={'/'}>
        <Route
          lazy={() => import('./app/dashboard')}
          loader={defaultLoader}
          index />

        <Route
          lazy={() => import('./app/api-docs')}
          loader={defaultLoader}
          path={'api-docs'} />

        <Route
          lazy={() => import('./app/authorize')}
          loader={defaultLoader}
          path={'authorize'} />

        <Route
          lazy={() => import('./app/credit-case-new')}
          loader={defaultLoader}
          path={'credit-cases/new/*'} />

        <Route
          lazy={() => import('./app/credit-case')}
          loader={defaultLoader}
          path={'credit-cases/:id/*'} />

        <Route
          lazy={() => import('./app/credit-cases')}
          path={'credit-cases'} />

        <Route
          lazy={() => import('./app/credit_cases')}
          loader={defaultLoader}
          path={'credit_cases/:id/*'} />

        <Route
          lazy={() => import('./app/credit-checks-search')}
          loader={defaultLoader}
          path={'credit-checks/search/:query?'} />

        <Route
          lazy={() => import('./app/credit-checks-pay')}
          loader={defaultLoader}
          path={'credit-checks/pay'} />

        <Route
          lazy={() => import('./app/credit-check')}
          path={'credit-checks/:id'} />

        <Route
          lazy={() => import('./app/credit-checks')}
          path={'credit-checks'} />

        <Route
          lazy={() => import('./app/company')}
          loader={defaultLoader}
          path={'company'}>
          <Route
            lazy={() => import('./app/company/route-info')}
            loader={defaultLoader}
            path={'info'} />

          <Route
            lazy={() => import('./app/company/route-info-edit')}
            loader={defaultLoader}
            path={'info/edit'} />

          <Route
            lazy={() => import('./app/company/route-administrations')}
            loader={defaultLoader}
            path={'administrations'} />

          <Route
            lazy={() => import('./app/company/route-administrations-new')}
            loader={defaultLoader}
            path={'administrations/new'} />

          <Route
            lazy={() => import('./app/company/route-administrations-update')}
            loader={defaultLoader}
            path={'administrations/:id'} />

          <Route
            lazy={() => import('./app/company/route-preferences')}
            loader={defaultLoader}
            path={'preferences'} />

          <Route
            lazy={() => import('./app/company/route-preferences-edit')}
            loader={defaultLoader}
            path={'preferences/edit'} />

          <Route
            lazy={() => import('./app/company/route-users')}
            loader={defaultLoader}
            path={'users'} />

          <Route
            lazy={() => import('./app/company/route-users-new')}
            loader={defaultLoader}
            path={'users/new'} />

          <Route
            lazy={() => import('./app/company/route-invoices')}
            loader={defaultLoader}
            path={'invoices'} />

          <Route
            lazy={() => import('./app/company/route-authorisations')}
            path={'authorisations'} />
        </Route>

        <Route
          lazy={() => import('./app/account')}
          loader={defaultLoader}
          path={'account'} />

        <Route
          lazy={() => import('./app/account-edit')}
          loader={defaultLoader}
          path={'account/edit'} />
      </Route>

      <Route
        element={<Navigate to={'/'} />}
        path={'*'} />
    </Route>
  </Route>
))

export default function Routes() {
  return (
    <RouterProvider
      fallbackElement={<FullScreenLoader />}
      router={appRoutes} />
  )
}

import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FullScreenLoader from '@oi/react/components/full-screen-loader'
import { useOnMountEffect } from '@oi/react/modules/utils'

export default function AuthorizeRoute() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useOnMountEffect(() => {
    if (searchParams.has('oauth_token')) {
      navigate(`/login?oauth_token=${searchParams.get('oauth_token')}`, { replace: true })

    } else {
      navigate('/login', { replace: true })
    }
  })

  return <FullScreenLoader />
}

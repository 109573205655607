import React from 'react'
import { buttonClasses } from '@mui/material/Button'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { ArrowDownSLineIcon } from '@oi/react/components/icons'

import type { Theme as MuiTheme, ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      primaryBackground: string
      extraLightPurple: string
    }
  }

  interface PaletteOptions {
    custom: {
      primaryBackground: string
      extraLightPurple: string
    }
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft: true
  }
}

export type Theme = MuiTheme

export function getThemeOptions(LinkBehavior: React.ElementType): ThemeOptions {
  return {
    typography: {
      fontFamily: [
        'Open Sans',
        '-apple-system',
        'sans-serif',
        'Roboto'
      ].join(','),

      fontWeightBold: 700,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontSize: 16,

      h1: {
        fontSize: 31,
        fontWeight: 600,
        lineHeight: '32px'
      },

      h2: {
        fontSize: 25,
        fontWeight: 400,
        lineHeight: 1.2
      },

      // h3: {
      //   fontSize: 32,
      //   lineHeight: 1.5
      // },
      //
      // h4: {
      //   fontSize: 24,
      //   lineHeight: 1.5,
      //   fontWeight: 600
      // },
      //
      // h5: {
      //   fontSize: 18,
      //   lineHeight: 1.5,
      //   fontWeight: 600
      // },

      h6: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 600
      },

      subtitle1: {
        fontSize: 20,
        lineHeight: 1.25,
        fontWeight: 600
      },

      subtitle2: {
        fontSize: 14,
        lineHeight: 1,
        fontWeight: 600
      },

      body1: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 400
      },

      body2: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.3
      },

      caption: {
        fontSize: 13,
        lineHeight: 1,
        fontWeight: 400
      },

      // overline: {
      //   fontSize: 12,
      //   lineHeight: 2.46
      // },

      button: {
        fontSize: 16,
        lineHeight: '26px',
        fontWeight: 'bold',
        textTransform: 'none'
      }
    },

    palette: {
      text: {
        primary: 'rgba(28, 7, 90, 1)'
      },

      primary: {
        light: 'rgba(255, 0, 119, 0.5)',
        main: 'rgba(255, 0, 119, 1)'
      },

      secondary: {
        light: '#C3C3EE',
        main: '#0101F9',
        dark: '#1C075A'
      },

      divider: 'rgba(1,1,249,0.2)',

      background: {
        paper: 'rgb(245,245,255)'
      },

      custom: {
        primaryBackground: '#FEF6F8',
        extraLightPurple: '#F5F5FF'
      }
    },

    shape: {
      borderRadius: 3
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            backgroundColor: '#FFFFFF',
            WebkitFontSmoothing: 'auto'
          },
          body: {
            minHeight: '100vh',
            backgroundColor: '#FFFFFF',

            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale'
          }
        }
      },

      MuiContainer: {
        defaultProps: {
          maxWidth: 'lg'
        }
      },

      MuiTypography: {
        defaultProps: {
          color: 'text.primary'
        }
      },

      MuiLink: {
        defaultProps: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          component: LinkBehavior,
          underline: 'none'
        },
        styleOverrides: {
          underlineAlways: ({ theme }) => ({
            fontWeight: theme.typography.fontWeightMedium
          }),

          underlineNone: ({ theme }) => ({
            color: theme.palette.text.primary,

            '&:hover': {
              color: theme.palette.primary.main
            }
          }),

          underlineHover: ({ theme }) => ({
            color: theme.palette.text.primary,

            '&:hover': {
              color: theme.palette.primary.main
            }
          })
        }
      },

      MuiButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
          disableElevation: true,
          size: 'medium'
        },
        variants: [
          {
            props: { variant: 'soft', color: 'secondary' },
            style: ({ theme }) => ({
              backgroundColor: '#F5F5FF',
              color: theme.palette.secondary.main,
              fontSize: 15,
              fontWeight: theme.typography.fontWeightMedium,

              '&:hover': {
                backgroundColor: theme.palette.secondary.light
              }
            })
          }
        ],
        styleOverrides: {
          root: {
            borderRadius: 100
          },

          sizeMedium: {},

          sizeSmall: {
            fontSize: 14
          },

          contained: ({ theme }) => ({
            [`&.${buttonClasses.colorPrimary}.Mui-disabled`]: {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              opacity: theme.palette.action.disabledOpacity
            },

            [`&.${buttonClasses.colorSecondary}.Mui-disabled`]: {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.secondary.main,
              opacity: theme.palette.action.disabledOpacity
            },

            [`& .${buttonClasses.loadingIndicator}`]: {
              color: theme.palette.common.white
            }
          }),

          text: ({ theme }) => ({
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            borderRadius: theme.shape.borderRadius
          }),

          outlined: {
            borderWidth: 2,

            '&.Mui-disabled': {
              borderWidth: 2
            }
          }
        }
      },

      MuiIconButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
          size: 'medium'
        },

        styleOverrides: {
          sizeSmall: ({ theme }) => ({
            fontSize: theme.typography.pxToRem(14)
          }),

          sizeMedium: ({ theme }) => ({
            padding: 5,
            fontSize: theme.typography.pxToRem(18)
          })
        }
      },

      MuiFab: {
        defaultProps: {
          LinkComponent: LinkBehavior
        }
      },

      MuiPaper: {
        styleOverrides: {
          outlined: {
            border: 'none'
          },

          elevation: ({ theme }) => ({
            boxShadow: '0px 0px 6px 0px rgba(28,7,90,0.1)',
            backgroundColor: theme.palette.background.default
          })
        }
      },

      MuiChip: {
        defaultProps: {
          size: 'small'
        },
        styleOverrides: {
          root: ({ theme }) => ({
            ...theme.typography.body2,
            borderRadius: 3
          })
        }
      },

      MuiTextField: {
        defaultProps: {
          size: 'small'
        }
      },

      MuiOutlinedInput: {
        defaultProps: {
          size: 'small'
        },

        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: '#F5F5FF',

            '&.Mui-focused': {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.secondary.main
              }
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'transparent'
            },

            [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'transparent'
            }
          }),

          colorSecondary: ({ theme }) => ({
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.dark,

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.secondary.light
            },

            '& input::placeholder': {
              color: theme.palette.secondary.dark
            }
          }),

          input: ({ theme }) => ({
            padding: theme.spacing(1.5, 2)
          }),

          inputSizeSmall: ({ theme }) => ({
            padding: theme.spacing(1, 1.5)
          }),

          multiline: {
            padding: 0
          }
        }
      },

      MuiAutocomplete: {
        defaultProps: {
          size: 'small'
        },

        styleOverrides: {
          root: {
            [`.${outlinedInputClasses.root}.${outlinedInputClasses.sizeSmall}`]: {
              padding: 0
            }
          },

          paper: {
            padding: 0
          },

          inputRoot: {
            flexWrap: 'inherit',
            padding: 0,

            '& > svg': {
              marginLeft: 14
            }
          }
        }
      },

      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: 0
          }
        }
      },

      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 36
          }
        }
      },

      // @ts-expect-error Picker is from separate package
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            padding: 0
          }
        }
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: -6
          },

          label: {
            marginLeft: 8
          }
        }
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.primary,
            marginTop: 6
          })
        }
      },

      MuiRadio: {
        styleOverrides: {
          colorPrimary: ({ theme }) => ({
            color: theme.palette.primary.main
          })
        }
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            whiteSpace: 'initial'
          }
        }
      },

      MuiSelect: {
        defaultProps: {
          IconComponent: ArrowDownSLineIcon
        },
        styleOverrides: {
          icon: {
            fontSize: 18
          }
        }
      },

      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: ({ theme }) => ({
            fontSize: theme.typography.pxToRem(16)
          }),

          fontSizeMedium: ({ theme }) => ({
            fontSize: theme.typography.pxToRem(18)
          })
        }
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          },
          message: {
            lineHeight: 1.4
          }
        }
      },

      MuiDialogTitle: {
        defaultProps: {
          color: 'text.primary',
          fontWeight: 'bold',
          variant: 'h2'
        }
      },

      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            paddingTop: 0,
            paddingBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2)
          })
        }
      },

      MuiDialogContentText: {
        defaultProps: {
          color: 'text.primary'
        }
      },

      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(3),
            paddingTop: theme.spacing(4)
          })
        }
      }
    }
  }

}

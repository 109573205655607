import React from 'react'
import { LoaderFunctionArgs, Outlet, redirect } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import { styled } from '@mui/material/styles'
import Footer from '@oi/react/components/footer'
import Header from '@oi/react/components/header'
import { getMe, useActiveUser } from '@oi/react/modules/api'
import { useOnMountEffect } from '@oi/react/modules/utils'

export async function appLoader({ request }: LoaderFunctionArgs) {
  try {
    return await getMe()

  } catch {
    const params = new URLSearchParams()
    const from = new URL(request.url).pathname

    if (from && from !== '/') {
      params.set('from', new URL(request.url).pathname)
    }

    return redirect('/login?' + params.toString())
  }
}

const StyledAppRoot = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
}))

const StyledContainer = styled(Container)(({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export default function App() {
  const intercom = useIntercom()
  const activeUser = useActiveUser()

  useOnMountEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }

    intercom.boot({
      userId: `${activeUser.intercomId}`,
      email: activeUser.email,
      name: activeUser.name,
      createdAt: activeUser.createdAt,
      company: {
        companyId: `${activeUser.company.intercomId}`,
        name: `${activeUser.company.companyName}`
      }
    })
  })

  return (
    <StyledAppRoot>
      <Header />

      <StyledContainer>
        <Grid
          py={{ xs: 3, md: 5 }}
          spacing={{ xs: 3, md: 5 }}
          container>
          <Outlet />
        </Grid>

        <Grid flexGrow={1} />

        <Footer variant={'in-app'} />
      </StyledContainer>
    </StyledAppRoot>
  )
}

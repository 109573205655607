import { userApi } from './api.constants'
import { useWrapMethod } from './api.internal'
import { UserApiUserRegisterRequest } from './open-api'

export function useRegisterMutation() {
  return useWrapMethod<
    UserApiUserRegisterRequest,
    void
  >(
    (params) => userApi.userRegister(params)
  )
}
